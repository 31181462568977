<!-- ......banner start....... -->
<app-banner></app-banner>
<!-- ......banner End....... -->

<!-- ......content one start....... -->
<app-content-one></app-content-one>
<!-- ......content one End....... -->

<!-- ......about us start....... -->
<app-home-aboutus></app-home-aboutus>
<!-- ......about us End....... -->

<!-- ........How It Works start .....  -->
<app-how-it-works></app-how-it-works>
<!-- ........How It Works end .....  -->

<!-- ........Our Services start......  -->
<app-service></app-service>
<!-- ...........Our Services end .......  -->

<!-- ......Happy Customers(Testimonials) start....... -->
<app-testimonial></app-testimonial>
<!-- ......Happy Customers(Testimonials) End ......  -->

<!-- .......content tow start........ -->
<app-content-two></app-content-two>
<!-- ......content two End ........ -->

<!-- ...........blog start.........  -->
<app-blog></app-blog>
<!-- ........blog end ........  -->

<!-- ........Contact Us start .......  -->
<app-small-enquiry></app-small-enquiry>
<!-- .........Contact Us End............  -->