export const APIConstants = {
    

    Enquiry: "enquiry/",

    ServiceSearch: "service/search",
    NoAuthService: "service",

    TestimonialSearch:"testimonial/search",
    BlogSearch:"blog/search",
    NoAuthBlog:"blog",
    
}

