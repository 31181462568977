import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorModel } from '../Model/error-model';

declare var $: any
@Injectable({
  providedIn: 'root'
})
export class BaseService {

  public UserProfile: any;
  public MobNumberPattern = "^((\\+971-?)|0)?[0-9]{7}$";
  public EmailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";

  constructor(private http: HttpClient) { }

  public Get(routePath: string) {
    return this.http.get<any>(environment.apiBaseUrl + routePath, this.getHeader());
  }

  public Post(routePath: string, data: any) {
    return this.http.post<any>(environment.apiBaseUrl + routePath, data, this.getHeader())
  }

  private getHeader(isAuth: boolean = false) {
    var reqHeader: any;
    if (isAuth) {
      reqHeader = new HttpHeaders(
        {
          'Content-Type': 'application/json',
        }
      );
    }
    else {
      reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return { headers: reqHeader };
  }


  public Error(err: any) {
    console.clear();
    var eModel = new ErrorModel();
    eModel.StatusCode = err.status;
    if (err.error && err.error.Message) {
      eModel.Message = err.error.Message;
    }
    console.log("Status code:" + eModel.StatusCode);
    console.log("Message:" + eModel.Message);

    return eModel;

  }

  public ScrollTop() {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  }

  public MenuActive(menuId: any) {
    if (menuId) {
      $("#" + menuId).parent().siblings().children('.active').removeClass('active')
      $("#" + menuId).addClass('active');
    }
    else {
      $('#navigation a').on('click',  (event: any)=> {
        $(this).parent().siblings().children('.active').removeClass('active')
        $(this).addClass('active');
      });
    }
  }


  
}
