import { Component, OnInit } from '@angular/core';
import { APIConstants } from 'src/app/repository/apiconstants';
import { FilterBaseModel } from 'src/app/repository/Model/Filter/filter-base-model.model';
import { FilterResponseModel } from 'src/app/repository/Model/Filter/filter-response-model.model';
import { BaseService } from 'src/app/repository/Service/base.service';

declare var $: any;
@Component({
	selector: 'app-testimonial',
	templateUrl: './testimonial.component.html',
	styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent implements OnInit {

	testimonials: any;
	constructor(public baseService: BaseService) { }

	ngOnInit(): void {
		this.testimonials = [];
		this.baseService.ScrollTop();
		this.baseService.MenuActive("testimonial");
		this.GetTestimonial();


	}

	GetTestimonial() {
		var fModel = new FilterBaseModel();
		fModel.IsFeatured = true;
		this.baseService.Post(APIConstants.TestimonialSearch, fModel).subscribe(
			(response: FilterResponseModel) => {
				this.testimonials = response.Result;
				$('.slide-one-item').owlCarousel({
					center: false,
					items: 1,
					loop: true,
					stagePadding: 0,
					margin: 0,
					autoplay: true,
					smartSpeed: 1000,
					pauseOnHover: false,
					nav: true,
					navText: ['<span class="icon-keyboard_arrow_left">', '<span class="icon-keyboard_arrow_right">']
				});
			},
			(err: any) => {

				this.baseService.Error(err);

			});

	}

}
