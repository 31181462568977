import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllBlogComponent } from './BlogManagement/all-blog/all-blog.component';
import { BlogDetailsComponent } from './BlogManagement/blog-details/blog-details.component';
import { AboutUsComponent } from './CommonManagement/about-us/about-us.component';
import { ContactUsComponent } from './CommonManagement/contact-us/contact-us.component';
import { HomeComponent } from './CommonManagement/home/home.component';
import { ServiceDetailsComponent } from './ServiceManagement/service-details/service-details.component';
import { AllTestimonialComponent } from './TestimonialManagement/all-testimonial/all-testimonial.component';

const routes: Routes = [

  {path:"",component:HomeComponent},
  { path: 'services/:Id/:title', component: ServiceDetailsComponent },
  {path:"blogs",component: AllBlogComponent},
  {path:'about-us',component:AboutUsComponent},
  {path:'contact-us', component: ContactUsComponent},
  {path:'blog/:Id/:title',component:BlogDetailsComponent},
  {path: 'testimonials',component:AllTestimonialComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
