import { Component, OnInit } from '@angular/core';
import { APIConstants } from 'src/app/repository/apiconstants';
import { FilterBaseModel } from 'src/app/repository/Model/Filter/filter-base-model.model';
import { FilterResponseModel } from 'src/app/repository/Model/Filter/filter-response-model.model';
import { BaseService } from 'src/app/repository/Service/base.service';

declare var $: any;
@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {
  services: any;
  sFModel: FilterBaseModel = new FilterBaseModel();

  constructor(public baseService: BaseService) { }

  ngOnInit(): void {
    this.baseService.ScrollTop();
    this.baseService.MenuActive("Service");
    this.GetServices();
  }

  GetServices() {
    this.baseService.Post(APIConstants.ServiceSearch, this.sFModel).subscribe(
      (response: FilterResponseModel) => {
        this.services = response.Result;
      },
      (err: any) => {

        this.baseService.Error(err);

      });

  }


  
}
