<div class="site-blocks-cover overlay" style="background-image: url(../../../assets/images/img_1.jpg);" data-aos="fade">
    <div class="container">
      <div class="row align-items-center justify-content-center">
  
        
            <div class="col-md-8 mt-lg-5 text-center">
              <h1>A Basic Guide to Starting a Franchise in the NYC</h1>
              <p class="post-meta">March 21, 2019 &bull; Posted by <a>Admin</a> in <a>Events</a></p>
              
            </div>
          
      </div>
    </div>
  </div> 

<section class="site-section">
    <div class="container">
        <div class="col-xl-12 col-md-12 text-center" *ngIf="!blog">
            <div class="about_info">
              <label>Please wait <i class="fa fa-spinner fa-spin"></i> </label>
            </div>
          </div>
      <div class="row">
        <div class="col-md-8 blog-content">
          <div class="row mb-5">
            <div class="col-lg-8">
              <figure><img src="{{blog?.ImageUrl}}"  onError="this.src='assets/images/hero_1.jpg';" alt="{{blog?.Title}}" title="{{blog?.Title}}" class="img_size">
                <figcaption>{{blog?.MetaTitle}}</figcaption></figure>
            </div>
          </div>
          <div class="col-md-12">
          <p class="lead" [innerHTML]="blog?.ShortDescription"></p>

          <blockquote><p style="word-break: break-all" [innerHTML]="blog?.LongDescription"></p></blockquote>
</div>
         
         <!-- <div class="pt-5">
            <p>Categories:  <a href="#">Design</a>, <a href="#">Events</a>  Tags: <a href="#">#html</a>, <a href="#">#trends</a></p>
          </div> -->

        </div>
        <div class="col-md-4 sidebar">
          
          <div class="sidebar-box">
            <div class="categories">
              <h3>Categories</h3>
              <li><a>{{blog?.BlogCategory?.Name}} <span></span></a></li>
            </div>
            
          </div>
          <div class="sidebar-box">
            <h3>Tags</h3>
            <p><a class="tag tag-primary" *ngFor="let btag of blog?.TagList"> {{btag}}  </a></p>
          </div>
        </div>
      </div>
    </div>
  </section>
