import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIConstants } from 'src/app/repository/apiconstants';
import { Constants } from 'src/app/repository/constants';
import { ErrorModel } from 'src/app/repository/Model/error-model';
import { FilterBaseModel } from 'src/app/repository/Model/Filter/filter-base-model.model';
import { FilterResponseModel } from 'src/app/repository/Model/Filter/filter-response-model.model';
import { ResponseModel } from 'src/app/repository/Model/response-model.model';
import { BaseService } from 'src/app/repository/Service/base.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.css']
})
export class ServiceDetailsComponent implements OnInit {
  service: any;
  serviceId!: number;
  services: any

  constructor(public baseService: BaseService
    // , private titleService: Title
    // , private meta: Meta
    // , private metaService: MetaService
    , private router: Router
    ,private activatedRoute: ActivatedRoute
    // ,private sanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
    this.baseService.MenuActive("services");
    this.baseService.ScrollTop();
    this.serviceId = parseInt(this.activatedRoute.snapshot.paramMap.get(Constants.Id)!);
    if (this.serviceId) {
      this.GetServiceDetail();
    }
    this.GetServices();
  }


  GetServiceDetail() {
    var cModel = {
      Id: this.serviceId,
      OrgHashKey: environment.OrganisationHashKey
    };

    this.baseService.Post(APIConstants.NoAuthService, cModel).subscribe(
      (response: ResponseModel) => {
        this.service = response.Result;
        // this.service.LongDescription= this.sanitizer.bypassSecurityTrustHtml(response.Result.LongDescription);

        // this.titleService.setTitle(this.service.MetaTitle);
        // this.meta.updateTag({ name: 'description', content: this.service.MetaDescription });
        // this.meta.updateTag({ name: 'keywords', content: this.service.MetaKeywords });
        // this.metaService.createCanonicalURL();
      },
      (err: any) => {
        var eModel = new ErrorModel();
        eModel = this.baseService.Error(err);
      });
  }

  GetServices() {
    var cFModel = new FilterBaseModel();
    this.baseService.Post(APIConstants.ServiceSearch, cFModel).subscribe(
      (response: FilterResponseModel) => {
        this.services = response.Result;
      },
      (err: any) => {

        this.baseService.Error(err);

      });

  }

}
