<footer class="site-footer">
    <div class="container">
      <div class="row">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-5">
              <h2 class="footer-heading mb-4">About Us</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque facere laudantium magnam voluptatum autem. Amet aliquid nesciunt veritatis aliquam.</p>
            </div>
            <div class="col-md-3 ml-auto">
              <h2 class="footer-heading mb-4">Useful Links</h2>
              <ul class="list-unstyled">
               
                <li><a routerLink="/about-us" class="smoothscroll">About Us</a></li>
                <li><a href="#services-section" class="smoothscroll">Services</a></li>
                <li><a routerLink="/testimonials" class="smoothscroll">Testimonials</a></li>
              </ul>
            </div>
            <div class="col-md-3 ml-auto">
              <h2 class="footer-heading mb-4">Important Links</h2>
              <ul class="list-unstyled">
               
                <li><a routerLink="/contact-us" class="smoothscroll">Contact Us</a></li>
                <li><a routerLink="/blogs" class="smoothscroll">Blogs</a></li>
                <li><a routerLink="#" class="smoothscroll">Terms</a></li>
              </ul>
            </div>
            
          </div>
        </div>
        <div class="col-md-3 footer-social">
          <h2 class="footer-heading mb-4">Follow Us</h2>

          <a href="#" class="pl-0 pr-3"><span class="icon-facebook"></span></a>
          <a href="#" class="pl-3 pr-3"><span class="icon-twitter"></span></a>
          <a href="#" class="pl-3 pr-3"><span class="icon-instagram"></span></a>
          <a href="#" class="pl-3 pr-3"><span class="icon-linkedin"></span></a>
        </div>
        <!-- <div class="col-md-3">
          <h2 class="footer-heading mb-4">Subscribe Newsletter</h2>
          <form action="#" method="post" class="footer-subscribe">
            <div class="input-group mb-3">
              <input type="text" class="form-control border-secondary text-white bg-transparent" placeholder="Enter Email" aria-label="Enter Email" aria-describedby="button-addon2">
              <div class="input-group-append">
                <button class="btn btn-primary text-black" type="button" id="button-addon2">Send</button>
              </div>
            </div>
          </form>
        </div> -->
      </div>
      <div class="row pt-2 mt-1 text-center">
        <div class="col-md-12">
          <div class="border-top pt-1">
            <p class="copyright"><small>
            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
          Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved
          <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --></small></p>
      
          </div>
        </div>
        
      </div>
    </div>
  </footer>