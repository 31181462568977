    <section class="site-section bg-light" id="contact-section" data-aos="fade">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 text-center">
            <h2 class="section-title mb-3">Contact Us</h2>
          </div>
        </div>
       
        <div class="row">
          <div class="col-md-12 mb-5">
    
            
    
            <!-- <form action="#" class="p-5 bg-white"> -->
              <form class="form-horizontal" class="p-5 bg-white" #sendEnquiry="ngForm" (ngSubmit)="SendEnquiry(sendEnquiry)">
  
              <h2 class="h4 text-black mb-5">Contact Form</h2> 
              <div class="row">
                <div class="col-md-12">
                    <span *ngIf="!serverMessage" style="color:#5cb85c; font-size: 18px">{{Servermessage}}</span>
                    <!-- <span style="color:#ff2222; font-size: 18px">{{serverMessage}}</span> -->
                    <i id="iSpinner" style="color:#5cb85c; font-size: 18px"
                        class="fa fa-spinner fa-spin">
                    </i><br>
                    <span style="color:#ff2222; font-size: 18px">{{serverMessage}}</span>
  
  
                    <span class="error" *ngIf="(!valYourName.valid && valYourName.touched)
                ||(!valMobile.valid && valMobile.touched)
                ||(!valEmail.valid && valEmail.touched)
                ||(!valMessage.valid && valMessage.touched)">
                        <h4 style="color: #fd0101;">Highlighted Field(s) Required</h4>
                    </span>
                </div>
            </div>
              <div class="row form-group">
                <div class="col-md-6 mb-3 mb-md-0">
                  <label class="text-black" for="fname">First Name</label>
                  <input type="text" id="fname" class="form-control" placeholder="Enter your Full Name"
                  name="entername" [(ngModel)]="contactus.Name" required
                  #valYourName="ngModel">
                </div>
                <div class="col-md-6">
                  
                    <label class="text-black" for="fmobile">Mobile Number</label>
                    <input type="text" id="fmobile" class="form-control" placeholder="Enter Phone Number"
                    name="enternumber" [(ngModel)]="contactus.Mobile" required #valMobile="ngModel">
                    <div *ngIf="valMobile.touched && valMobile.invalid">
                      <label class="error red">Please,
                          Enter valid Mobile Number</label>
                  </div>
                  </div>
              
              </div>
    
              <div class="row form-group">
                
                
                <div class="col-md-12">
                  <label class="text-black" for="lemail">Email</label>
                  <input type="email" id="lemail" class="form-control" [pattern]="baseService.EmailPattern" placeholder="Enter Your Email"
                  name="enteremail" [(ngModel)]="contactus.Email" required
                  #valEmail="ngModel">
                </div>
              </div>  
    
              <div class="row form-group">
                <div class="col-md-12">
                  <label class="text-black" for="message">Message</label> 
                  <textarea name="message" id="message" cols="30" rows="7" class="form-control" placeholder="Write Your Message" name="message" [(ngModel)]="contactus.Message" required #valMessage="ngModel"></textarea>
                </div>
              </div>
    
              <div class="row form-group">
                <div class="col-md-12">
                  <input type="submit"  [disabled]="!sendEnquiry.form.valid" value="Send Message" class="btn btn-primary btn-md text-white">
                </div>
              </div>
    
    
            </form>
          </div>
          
        </div>
      </div>
    </section>