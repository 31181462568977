import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './CommonManagement/home/home.component';
import { ContactUsComponent } from './CommonManagement/contact-us/contact-us.component';
import { BlogDetailsComponent } from './BlogManagement/blog-details/blog-details.component';
import { BlogComponent } from './BlogManagement/blog/blog.component';
import { AllBlogComponent } from './BlogManagement/all-blog/all-blog.component';
import { AllTestimonialComponent } from './TestimonialManagement/all-testimonial/all-testimonial.component';
import { TestimonialComponent } from './TestimonialManagement/testimonial/testimonial.component';
import { TestimonialDetailsComponent } from './TestimonialManagement/testimonial-details/testimonial-details.component';
import { ServiceDetailsComponent } from './ServiceManagement/service-details/service-details.component';
import { AllServiceComponent } from './ServiceManagement/all-service/all-service.component';
import { ServiceComponent } from './ServiceManagement/service/service.component';
import { HowItWorksComponent } from './CommonManagement/how-it-works/how-it-works.component';
import { AboutUsComponent } from './CommonManagement/about-us/about-us.component';
import { BannerComponent } from './CommonManagement/banner/banner.component';
import { FooterComponent } from './CommonManagement/footer/footer.component';
import { ContentOneComponent } from './CommonManagement/content-one/content-one.component';
import { ContentTwoComponent } from './CommonManagement/content-two/content-two.component';
import { HeaderComponent } from './CommonManagement/header/header.component';
import { HttpClientModule , HTTP_INTERCEPTORS} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SmallEnquiryComponent } from './CommonManagement/small-enquiry/small-enquiry.component';
import { HomeAboutusComponent } from './CommonManagement/home-aboutus/home-aboutus.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactUsComponent,
    BlogDetailsComponent,
    BlogComponent,
    AllBlogComponent,
    AllTestimonialComponent,
    TestimonialComponent,
    TestimonialDetailsComponent,
    ServiceDetailsComponent,
    AllServiceComponent,
    ServiceComponent,
    HowItWorksComponent,
    AboutUsComponent,
    BannerComponent,
    FooterComponent,
    HeaderComponent,
    ContentOneComponent,
    ContentTwoComponent,
    SmallEnquiryComponent,
    HomeAboutusComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
