<div class="bradcam_area bradcam_bg_2">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="bradcam_text text-center">
            <h3>A Basic Guide to Starting a Franchise in the NYC
            </h3>
            <p class="post-meta">March 21, 2019 &bull; Posted by <a>Admin</a> in <a href="#">Events</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>

<section class="site-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 blog-content">
          <div class="pt-5">
            <h3 class="mb-5">Testimonials</h3>
            <ul class="comment-list row">
              <li class="comment col-md-5" *ngFor="let test of testimonials">
                <div class="vcard bio">
                  <img src="{{test.ProfileUrl}}" onError="this.src='assets/images/hero_1.jpg';" alt="{{test.AuthorName}}" title="{{test.AuthorName}}">
                </div>
                <div class="comment-body">
                  <h3>{{test.AuthorName}}</h3>
                  <!-- <div class="meta">January 9, 2018 at 2:21pm</div> -->
                  <p [innerHTML]="testimonials.Feedback"></p>
                  <p>
                      <!-- <a href="#" class="reply">Reply</a> -->
                    </p>
                </div>
              </li>

              <li class="comment col-md-5">
                <div class="vcard bio">
                  <img src="../../../assets/images/person_1.jpg" alt="Image placeholder">
                </div>
                <div class="comment-body">
                  <h3>Jean Doe</h3>
                  <div class="meta">January 9, 2018 at 2:21pm</div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur quidem laborum necessitatibus, ipsam impedit vitae autem, eum officia, fugiat saepe enim sapiente iste iure! Quam voluptas earum impedit necessitatibus, nihil?</p>
                  <p><a href="#" class="reply">Reply</a></p>
                </div>

              
              </li>

            
            </ul>
           
          </div>

        </div>
      
      </div>
    </div>
  </section>
