import { Component, OnInit } from '@angular/core';
import { APIConstants } from 'src/app/repository/apiconstants';
import { FilterBaseModel } from 'src/app/repository/Model/Filter/filter-base-model.model';
import { FilterResponseModel } from 'src/app/repository/Model/Filter/filter-response-model.model';
import { BaseService } from 'src/app/repository/Service/base.service';

@Component({
  selector: 'app-all-blog',
  templateUrl: './all-blog.component.html',
  styleUrls: ['./all-blog.component.css']
})
export class AllBlogComponent implements OnInit {
  blogs: any

  constructor(public baseService: BaseService) { }

  ngOnInit(): void {
    this.baseService.ScrollTop();
		this.baseService.MenuActive("testimonial");
		this.GetBlog();
  }

  GetBlog() {
		var fModel = new FilterBaseModel();
		this.baseService.Post(APIConstants.BlogSearch, fModel).subscribe(
			(response: FilterResponseModel) => {
				this.blogs = response.Result;
		
			},
			(err: any) => {

				this.baseService.Error(err);

			});

    }
}
