
<section class="site-section" id="about-section">
    <div class="container">
  
      <div class="row">
        <div class="col-lg-6 mb-5" data-aos="fade-up" data-aos-delay="">
          <figure class="circle-bg">
          <img src="../../../assets/images/bankerimg/2.jpeg" alt="Free Website Template by Free-Template.co" class="img-fluid img_s">
          </figure>
        </div>
        <div class="col-lg-5 ml-auto" data-aos="fade-up" data-aos-delay="100">
          
  
          <div class="row">
  
            
            
            <div class="col-12 mb-4" data-aos="fade-up" data-aos-delay="">
              <div class="unit-4 d-flex">
                <div class="unit-4-icon mr-4 mb-3"><span class="text-primary flaticon-head"></span></div>
                <div>
                  <h3>Bank Loan</h3>
                  <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                  <p class="mb-0"><a href="#">Learn More</a></p>
                </div>
              </div>
            </div>
            <div class="col-12 mb-4" data-aos="fade-up" data-aos-delay="100">
              <div class="unit-4 d-flex">
                <div class="unit-4-icon mr-4 mb-3"><span class="text-primary flaticon-smartphone"></span></div>
                <div>
                  <h3>Banking Consulation </h3>
                  <p>Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.</p>
                  <p class="mb-0"><a href="#">Learn More</a></p>
                </div>
              </div>
            </div>
          </div>
  
  
          
        </div>
      </div>
  
      
    </div>
  </section>