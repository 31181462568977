<!-- bradcam_area  -->
<div class="bradcam_area bradcam_bg_2">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="bradcam_text text-center">
            <h3>{{service?.Name}}
            </h3>
            <p>Creating new digital benchmarks for resilient, resource efficient and green built environments</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--/ bradcam_area  -->
  
  <!-- about_area_start  -->
  <div class="about_area add_padding">
    <div class="container">
      <div class="border_1px">
        <div class="col-xl-12 col-md-12 text-center" *ngIf="!service">
          <div class="about_info">
            <label>Please wait <i class="fa fa-spinner fa-spin"></i> </label>
          </div>
        </div>
        <div class="row ">
          <div class="col-md-5">
            <div class="about_thumb">
              <img class="img_size" src="{{service?.ImageUrl}}" onError="this.src='assets/images/hero_1.jpg';" alt="{{service?.Name}}" title="{{service?.Name}}" />
              <div class="pattern_img d-none d-lg-block">
                <img src="../../assets/img/pattern/pattern.png" alt="">
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <!-- <div class="about_info">
              <h3 class="text-center" style="font-size: 30px;">{{service?.Name}}</h3>
            </div> -->
            <div class="about_info1">
              <p class="first_para">
                {{service?.ShortDescription}}
              </p>
              <br>
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col-xl-12 col-md-12" [innerHtml]="service?.LongDescription">
          </div>
        </div>
      </div>
  
    </div>
  </div>
  
  <div class="allservices row">
    <div class="col-md-1"></div>
    <div class="item col-md-2" *ngFor="let service of services">
      <a href="expertise/{{service.Id}}/{{service.RoutePath}}">
        <img src="{{service.ImageUrl}}" onError="this.src='assets/img/home_logo.png';" alt="{{service.Name}}"
          title="{{service.Name}}" class="img-fluid" style="cursor: pointer; height: 150px; width: 100%;">
      </a>
      <p class="text">
        <a href="expertise/{{service.Id}}/{{service.RoutePath}}">{{service.Name}}</a>
      </p>
    </div>
  </div>