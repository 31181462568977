import { environment } from 'src/environments/environment';

export class FilterBaseModel {
    SearchText!: string;
    OrderBy!: string;
    Order!: number;
    StartIndex: number = 0;
    RecordCount: number = 20;
    OrgHashKey: string = environment.OrganisationHashKey;
    IsFeatured!: boolean ;
    IsActive!: boolean;


}
