import { Component, OnInit } from '@angular/core';
import { APIConstants } from 'src/app/repository/apiconstants';
import { FilterBaseModel } from 'src/app/repository/Model/Filter/filter-base-model.model';
import { FilterResponseModel } from 'src/app/repository/Model/Filter/filter-response-model.model';
import { BaseService } from 'src/app/repository/Service/base.service';

@Component({
  selector: 'app-all-testimonial',
  templateUrl: './all-testimonial.component.html',
  styleUrls: ['./all-testimonial.component.css']
})
export class AllTestimonialComponent implements OnInit {
  testimonials: any;
  constructor(public baseService: BaseService) { }

  ngOnInit(): void {
		this.baseService.ScrollTop();
		this.baseService.MenuActive("testimonials");
    this.testimonials = [];
		this.GetTestimonial();
  }

  GetTestimonial() {
		var fModel = new FilterBaseModel();
		this.baseService.Post(APIConstants.TestimonialSearch, fModel).subscribe(
			(response: FilterResponseModel) => {
				this.testimonials = response.Result;
			},
			(err: any) => {

				this.baseService.Error(err);

			});

	}
}
