<section class="site-section">
    <div class="container">
  
      <div class="row mb-5 justify-content-center">
        <div class="col-md-7 text-center">
          <h2 class="section-title mb-3" data-aos="fade-up" data-aos-delay="">How It Works</h2>
          <p class="lead" data-aos="fade-up" data-aos-delay="100">A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
        </div>
      </div>
      
      <div class="row align-items-lg-center" >
        <div class="col-lg-6 mb-5" data-aos="fade-up" data-aos-delay="">
  
          <div class="owl-carousel slide-one-item-alt">
            <img src="/assets/images/slide_1.jpg" alt="Image" class="img-fluid">
            <img src="/assets/images/slide_2.jpg" alt="Image" class="img-fluid">
            <img src="/assets/images/slide_3.jpg" alt="Image" class="img-fluid">
          </div>
          <div class="custom-direction">
            <a href="#" class="custom-prev"><span><span class="icon-keyboard_backspace"></span></span></a>
            <a href="#" class="custom-next"><span><span class="icon-keyboard_backspace"></span></span></a>
          </div>
  
        </div>
        <div class="col-lg-5 ml-auto" data-aos="fade-up" data-aos-delay="100">
          
          <div class="owl-carousel slide-one-item-alt-text">
            <div>
              <h2 class="section-title mb-3">01. Online Applications</h2>
              <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.</p>
  
              <p><a href="#" class="btn btn-primary mr-2 mb-2">Learn More</a></p>
            </div>
            <div>
              <h2 class="section-title mb-3">02. Get an approval</h2>
              <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.</p>
              <p><a href="#" class="btn btn-primary mr-2 mb-2">Learn More</a></p>
            </div>
            <div>
              <h2 class="section-title mb-3">03. Card delivery</h2>
              <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.</p>
  
              <p><a href="#" class="btn btn-primary mr-2 mb-2">Learn More</a></p>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
  </section>