import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APIConstants } from 'src/app/repository/apiconstants';
import { EnquiryModel } from 'src/app/repository/Model/enquiry-model.model';
import { ErrorModel } from 'src/app/repository/Model/error-model';
import { ResponseModel } from 'src/app/repository/Model/response-model.model';
import { BaseService } from 'src/app/repository/Service/base.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  contactus: EnquiryModel = new EnquiryModel();
  serverMessage!: string;
  Servermessage!: string;
  constructor(public baseService: BaseService
    , private router: Router) { }

  ngOnInit(): void {
    $("#iSpinner").hide();
    this.baseService.ScrollTop();
    this.baseService.MenuActive("Contact")
    this.contactus.HashKey = environment.OrganisationHashKey;

  }

  SendEnquiry(sendEnquiry: any) {
    this.Servermessage = "Please wait";
    this.serverMessage = "";
    $("#iSpinner").show();
    this.baseService.Post(APIConstants.Enquiry, this.contactus).subscribe(
      (response: ResponseModel) => {
        sendEnquiry.reset();
        this.Servermessage = "Thanks for enquiry, we'll get back to you soon.";
        $("#iSpinner").hide();
        setTimeout(() => {
          this.serverMessage = "";
          this.Servermessage = "";
        }, 5000);
      },
      (err: any) => {
        $("#iSpinner").hide();
        var eModel = new ErrorModel();
        eModel = this.baseService.Error(err);
        this.serverMessage = eModel.Message;
        
      });
  }

}
