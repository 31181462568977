
export class EnquiryModel {
    Id!: number;
    Name!: string;
    Email!: string;
    Mobile!: string;
    Location!: string;
    ForId!: string;
    ModeId!: number;
    StatusId!: number;
    EnquiredOn!: Date;
    Message!: string;
    HashKey!: string;
    OrganisationHashKey!: string;
}

