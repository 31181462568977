<div class="site-section cta-big-image" id="about-section">
    <div class="container">
      <div class="row mb-5 justify-content-center">
        <div class="col-md-8 text-center">
          <h2 class="section-title mb-3" data-aos="fade-up" data-aos-delay="">About Us</h2>
          <p class="lead" data-aos="fade-up" data-aos-delay="100">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus minima neque tempora reiciendis.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 mb-5" data-aos="fade-up" data-aos-delay="">
          <figure class="circle-bg">
          <img src="../../../assets/images/bankerimg/1.jpeg" alt="Free Website Template by Free-Template.co" class="img-fluid img_s">
          </figure>
        </div>
        <div class="col-lg-5 ml-auto" data-aos="fade-up" data-aos-delay="100">
          
          <h3 class="text-black mb-4">We Solve Your Financial Problem</h3>
  
          <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.</p>
  
          <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.</p>
          
        </div>
      </div>    
      
    </div>  
  </div>