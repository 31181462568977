<header class="site-navbar js-sticky-header site-navbar-target" role="banner">

    <div class="container">
      <div class="row align-items-center">
        
        <div class="col-6 col-xl-2">
          <h1 class="mb-0 site-logo"><a routerLink="" class="h2 mb-0">CS-HKS<span class="text-primary">.</span> </a></h1>
        </div>
  
        <div class="col-12 col-md-10 d-none d-xl-block">
          <nav class="site-navigation position-relative text-right" role="navigation">
  
            <ul class="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
              <li><a routerLink="" class="nav-link">Home</a></li>
              <li class="has-children">
                <a routerLink="/about-us" class="nav-link">About Us</a>
                <ul class="dropdown">
                  <!-- <li><a routerLink="#team-section" class="nav-link">Team</a></li>
                  <li><a routerLink="#pricing-section" class="nav-link">Pricing</a></li> -->
                  <!-- <li><a routerLink="#faq-section" class="nav-link">FAQ</a></li>
                  <li><a routerLink="#gallery-section" class="nav-link">Gallery</a></li> -->
                  <li><a href="#services-section" class="nav-link">Services</a></li>
                  <li><a routerLink="/testimonials" class="nav-link">Testimonials</a></li>
                  <!-- <li class="has-children">
                    <a routerLink="#">More Links</a>
                    <ul class="dropdown">
                      <li><a routerLink="#">Menu One</a></li>
                      <li><a routerLink="#">Menu Two</a></li>
                      <li><a routerLink="#">Menu Three</a></li>
                    </ul>
                  </li> -->
                </ul>
              </li>
              
              
              <li><a routerLink="/blogs" class="nav-link">Blog</a></li>
              <li><a routerLink="/contact-us" class="nav-link">Contact</a></li>
              <li class="social"><a routerLink="#contact-section" class="nav-link"><span class="icon-facebook"></span></a></li>
              <li class="social"><a routerLink="#contact-section" class="nav-link"><span class="icon-twitter"></span></a></li>
              <li class="social"><a routerLink="#contact-section" class="nav-link"><span class="icon-linkedin"></span></a></li>
            </ul>
          </nav>
        </div>
  
  
        <div class="col-6 d-inline-block d-xl-none ml-md-0 py-3" style="position: relative; top: 3px;"><a routerLink="#" class="site-menu-toggle js-menu-toggle float-right"><span class="icon-menu h3"></span></a></div>
  
      </div>
    </div>
    
  </header>