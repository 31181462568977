import { Component, OnInit } from '@angular/core';

declare var $: any;
@Component({
	selector: 'app-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {


		$('.single-text').owlCarousel({
			center: false,
			items: 1,
			loop: true,
			stagePadding: 0,
			margin: 0,
			autoplay: true,
			pauseOnHover: false,
			nav: false,
			smartSpeed: 1000,
		});
	}

}
