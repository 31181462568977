import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  hash: any;

  constructor() { }

  ngOnInit(): void {

    var siteSticky = function() {
      $(".js-sticky-header").sticky({topSpacing:0});
    };
    siteSticky();
  
    // var siteMenuClone = ()=> {

    //   $('.js-clone-nav').each(()=> {
    //     var $this = $(this);
    //     $this.clone().attr('class', 'site-nav-wrap').appendTo('.site-mobile-menu-body');
    //   });
  

  
    //   $('body').on('click', '.arrow-collapse', (e: any)=> {
    //     var $this = $(this);
    //     if ( $this.closest('li').find('.collapse').hasClass('show') ) {
    //       $this.removeClass('active');
    //     } else {
    //       $this.addClass('active');
    //     }
    //     e.preventDefault();  
        
    //   });
  
    //   // $(window).resize(()=> {
    //   //   var $this = $(this),
    //   //     w = $this.width();
  
    //   //   if ( w > 768 ) {
    //   //     if ( $('body').hasClass('offcanvas-menu') ) {
    //   //       $('body').removeClass('offcanvas-menu');
    //   //     }
    //   //   }
    //   // })
  
    //   // $('body').on('click', '.js-menu-toggle', (e:any)=> {
    //   //   var $this = $(this);
    //   //   e.preventDefault();
  
    //   //   if ( $('body').hasClass('offcanvas-menu') ) {
    //   //     $('body').removeClass('offcanvas-menu');
    //   //     $this.removeClass('active');
    //   //   } else {
    //   //     $('body').addClass('offcanvas-menu');
    //   //     $this.addClass('active');
    //   //   }
    //   // }) 
  
    //   // click outisde offcanvas
    //   $(document).mouseup(function(e: any) {
    //     var container = $(".site-mobile-menu");
    //     if (!container.is(e.target) && container.has(e.target).length === 0) {
    //       if ( $('body').hasClass('offcanvas-menu') ) {
    //         $('body').removeClass('offcanvas-menu');
    //       }
    //     }
    //   });
    // }; 
    // siteMenuClone();

   }

}
