<div id="overlayer"></div>
<div class="loader">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>


<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>