import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIConstants } from 'src/app/repository/apiconstants';
import { Constants } from 'src/app/repository/constants';
import { ErrorModel } from 'src/app/repository/Model/error-model';
import { ResponseModel } from 'src/app/repository/Model/response-model.model';
import { BaseService } from 'src/app/repository/Service/base.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {
  blog: any;
  blogId!: number;
  constructor(public baseService: BaseService
    , private router: Router
    ,private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.baseService.MenuActive("blogdetail");
    this.baseService.ScrollTop();
    this.blogId = parseInt(this.activatedRoute.snapshot.paramMap.get(Constants.Id)!);
    if (this.blogId) {
      this.GetBlogDetail();
    }
  }

  GetBlogDetail() {
    var cModel = {
      Id: this.blogId,
      OrgHashKey: environment.OrganisationHashKey
    };

    this.baseService.Post(APIConstants.NoAuthBlog, cModel).subscribe(
      (response: ResponseModel) => {
        this.blog = response.Result;
       
      },
      (err: any) => {
        var eModel = new ErrorModel();
        eModel = this.baseService.Error(err);
      });
  }

}
