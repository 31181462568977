<!-- 

<div class="site-blocks-cover overlay" style="background-image: url(../../../assets/images/img_1.jpg);" data-aos="fade">
  <div class="container">
    <div class="row align-items-center justify-content-center">

      
          <div class="col-md-8 mt-lg-5 text-center">
            <h1>A Basic Guide to Starting a Franchise in the NYC</h1>
            <p class="post-meta">March 21, 2019 &bull; Posted by <a href="#">Admin</a> in <a href="#">Events</a></p>
            
          </div>
        
    </div>
  </div>
</div>   -->
<!-- bradcam_area  -->
<div class="bradcam_area bradcam_bg_2">
  <div class="container">
    <div class="row">
      <div class="col-xl-12">
        <div class="bradcam_text text-center">
          <h3>A Basic Guide to Starting a Franchise in the NYC
          </h3>
          <p class="post-meta">March 21, 2019 &bull; Posted by <a>Admin</a> in <a href="#">Events</a></p>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/ bradcam_area  -->
<section class="site-section" id="blog-section">
    <div class="container">
      <div class="row mb-5">
        <div class="col-12 text-center" data-aos="fade">
          <h2 class="section-title mb-3">Our Blog</h2>
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" *ngFor="let blog of blogs" data-aos="fade-up" data-aos-delay="">
          <div class="h-entry">
            <a>
              <img src="{{blog.ImageUrl}}" alt="{{blog.Title}}" onError="this.src='assets/images/hero_1.jpg';"
              title="{{blog.Title}}" alt="Image" class="img-fluid">
            </a>
            <h2 class="font-size-regular"><a>{{blog.Title}}</a></h2>
            <div class="meta mb-4">{{blog.BlogCategory?.Name}} <span class="mx-2">&#8226;</span> {{blog.PublishedDate}}<span class="mx-2">&#8226;</span>
               <!-- <a href="#">News</a> -->
            </div>
            <p [innerHTML]="blog.ShortDescription"></p>
            <p><a routerLink="/blog/{{blog.Id}}/{{blog.RoutePath}}">Continue Reading...</a></p>
          </div> 
        </div>
        
      </div>
    </div>
  </section>
  