import { Component, OnInit } from '@angular/core';
import { APIConstants } from 'src/app/repository/apiconstants';
import { FilterBaseModel } from 'src/app/repository/Model/Filter/filter-base-model.model';
import { FilterResponseModel } from 'src/app/repository/Model/Filter/filter-response-model.model';
import { BaseService } from 'src/app/repository/Service/base.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
blogs: any
  constructor(public baseService: BaseService) { }

  ngOnInit(): void {
		this.baseService.ScrollTop();
		this.baseService.MenuActive("testimonial");
    this.blogs = [];
		this.GetBlog();
  }


  GetBlog() {
		var fModel = new FilterBaseModel();
		fModel.IsFeatured = true;
		this.baseService.Post(APIConstants.BlogSearch, fModel).subscribe(
			(response: FilterResponseModel) => {
				this.blogs = response.Result;
		
			},
			(err: any) => {

				this.baseService.Error(err);

			});

	}
}
