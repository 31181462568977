<div class="site-section" id="next">
    <div class="container">
      <div class="row mb-5">
        <div class="col-md-4 text-center" data-aos="fade-up" data-aos-delay="">
          <img src="/assets/images/flaticon-svg/svg/001-wallet.svg" alt="Free Website Template by Free-Template.co" class="img-fluid w-25 mb-4">
          <h3 class="card-title">Money Savings</h3>
          <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
        </div>
        <div class="col-md-4 text-center" data-aos="fade-up" data-aos-delay="100">
          <img src="/assets/images/flaticon-svg/svg/004-cart.svg" alt="Free Website Template by Free-Template.co" class="img-fluid w-25 mb-4">
          <h3 class="card-title">Online Shoppings</h3>
          <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
        </div>
        <div class="col-md-4 text-center" data-aos="fade-up" data-aos-delay="200">
          <img src="/assets/images/flaticon-svg/svg/006-credit-card.svg" alt="Free Website Template by Free-Template.co" class="img-fluid w-25 mb-4">
          <h3 class="card-title">Credit / Debit Cards</h3>
          <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
        </div>
  
      </div>
  
      <div class="row">
        <div class="col-lg-6 mb-5" data-aos="fade-up" data-aos-delay="">
          <figure class="circle-bg">
          <img src="../../../assets/images/bankerimg/3.jpeg" alt="Free Website Template by Free-Template.co" class="img-fluid img_s">
          </figure>
        </div>
        <div class="col-lg-5 ml-auto" data-aos="fade-up" data-aos-delay="100">
          <div class="mb-4">
            <h3 class="h3 mb-4 text-black">Amortization Computation</h3>
            <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
            
          </div>
            
          <div class="mb-4">
            <ul class="list-unstyled ul-check success">
              <li>Officia quaerat eaque neque</li>
              <li>Lorem ipsum dolor sit amet</li>
              <li>Consectetur adipisicing elit</li>
            </ul>
            
          </div>
  
          <!-- <div class="mb-4">
            <form action="#">
              <div class="form-group d-flex align-items-center">
                <input type="text" class="form-control mr-2" placeholder="Enter your email">
                <input type="submit" class="btn btn-primary" value="Submit Email">
              </div>
            </form>
          </div> -->
  
          
          
        </div>
      </div>
    </div>
  </div>