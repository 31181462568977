import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-aboutus',
  templateUrl: './home-aboutus.component.html',
  styleUrls: ['./home-aboutus.component.css']
})
export class HomeAboutusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
