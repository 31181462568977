
<section class="site-section" id="blog-section">
    <div class="container">
      <div class="row mb-5">
        <div class="col-12 text-center" data-aos="fade">
          <h2 class="section-title mb-3"><a routerLink="/blogs"> Our Blog</a></h2>
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="">
          <div class="h-entry">
             <a>
              <img src="{{blogs[0]?.ImageUrl}}" alt="{{blogs[0]?.Title}}" onError="this.src='assets/images/hero_1.jpg';"
              title="{{blogs[0]?.Title}}" class="img-fluid">
            </a>
            <h2 class="font-size-regular"><a>{{blogs[0]?.Title}}</a></h2>
            <div class="meta mb-4">{{blogs[0]?.BlogCategory?.Name}} <span class="mx-2">&#8226;</span> {{blogs[0]?.PublishedDate}}<span class="mx-2">&#8226;</span> 
              <!-- <a href="#">News</a> -->
            </div>
            <p [innerHTML]="blogs[0]?.ShortDescription"></p>
            <p><a routerLink="/blog/{{blogs[0]?.Id}}/{{blogs[0]?.RoutePath}}">Continue Reading...</a></p>
          </div> 
        </div>
        <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="100">
          <div class="h-entry">
            <a>
              <img src="{{blogs[1]?.ImageUrl}}" alt="{{blogs[1]?.Title}}" onError="this.src='assets/images/hero_1.jpg';"
              title="{{blogs[1]?.Title}}" class="img-fluid">
            </a>
            <h2 class="font-size-regular"><a>{{blogs[1]?.Title}}</a></h2>
            <div class="meta mb-4">{{blogs[1]?.BlogCategory?.Name}} <span class="mx-2">&#8226;</span> {{blogs[1]?.PublishedDate}}<span class="mx-2">&#8226;</span> 
              <!-- <a href="#">News</a> -->
            </div>
            <p [innerHTML]="blogs[1]?.ShortDescription"></p>
            <p><a routerLink="/blog/{{blogs[1]?.Id}}/{{blogs[1]?.RoutePath}}">Continue Reading...</a></p>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="200">
          <div class="h-entry">
            <a>
              <img src="{{blogs[2]?.ImageUrl}}" alt="{{blogs[2]?.Title}}" onError="this.src='assets/images/hero_1.jpg';"
              title="{{blogs[2]?.Title}}" class="img-fluid">
            </a>
            <h2 class="font-size-regular"><a>{{blogs[2]?.Title}}</a></h2>
            <div class="meta mb-4">{{blogs[2]?.BlogCategory?.Name}} <span class="mx-2">&#8226;</span> {{blogs[2]?.PublishedDate}}<span class="mx-2">&#8226;</span> 
              <!-- <a href="#">News</a> -->
            </div>
            <p [innerHTML]="blogs[2]?.ShortDescription"></p>
            <p><a routerLink="/blog/{{blogs[2]?.Id}}/{{blogs[2]?.RoutePath}}">Continue Reading...</a></p>
          </div> 
        </div>
        
      </div>
    </div>
  </section>
  