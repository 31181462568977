import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
	selector: 'app-how-it-works',
	templateUrl: './how-it-works.component.html',
	styleUrls: ['./how-it-works.component.css']
})
export class HowItWorksComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {
		$('.slide-one-item-alt').owlCarousel({
			center: false,
			items: 1,
			loop: true,
				stagePadding: 0,
			margin: 0,
			smartSpeed: 1000,
			autoplay: true,
			pauseOnHover: true,
			mouseDrag: false,
			touchDrag: false,
		  });
		  $('.slide-one-item-alt-text').owlCarousel({
			center: false,
			items: 1,
			loop: true,
				stagePadding: 0,
			margin: 0,
			smartSpeed: 1000,
			autoplay: true,
			pauseOnHover: true,
			mouseDrag: false,
			touchDrag: false,
			
		  });
		  
	
		  $('.custom-next').click(function(e: any) {
			  e.preventDefault();
			  $('.slide-one-item-alt').trigger('next.owl.carousel');
			  $('.slide-one-item-alt-text').trigger('next.owl.carousel');
		  });
		  $('.custom-prev').click(function(e: any) {
			  e.preventDefault();
			  $('.slide-one-item-alt').trigger('prev.owl.carousel');
			  $('.slide-one-item-alt-text').trigger('prev.owl.carousel');
		  });
		  
		};
}
