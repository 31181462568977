<section class="site-section testimonial-wrap" id="testimonials-section" data-aos="fade">
    <div class="container">
      <div class="row mb-5">
        <div class="col-12 text-center">
          <h2 class="section-title mb-3">Happy Customers</h2>
        </div>
      </div>
    </div>
    <div class="slide-one-item home-slider owl-carousel">
        <div>
          <div class="testimonial">
            
            <blockquote class="mb-5">
              <p>&ldquo;{{testimonials[0]?.Feedback}}&rdquo;</p>
            </blockquote>
  
            <figure class="mb-4 d-flex align-items-center justify-content-center">
              <div><img src="{{testimonials[0]?.ProfileUrl}}" onError="this.src='assets/images/hero_1.jpg';" alt="{{testimonials[0]?.AuthorName}}" title="{{testimonials[0]?.AuthorName}}" class="w-50 img-fluid mb-3"></div>
              <p>{{testimonials[0]?.AuthorName}}</p>
            </figure>
          </div>
        </div>
        <div>
          <div class="testimonial">
  
            <blockquote class="mb-5">
              <p>&ldquo;{{testimonials[1]?.Feedback}}&rdquo;</p>
            </blockquote>
            <figure class="mb-4 d-flex align-items-center justify-content-center">
              <div><img src="{{testimonials[1]?.ProfileUrl}}" onError="this.src='assets/images/hero_1.jpg';" alt="{{testimonials[1]?.AuthorName}}"
                title="{{testimonials[1]?.AuthorName}}" class="w-50 img-fluid mb-3"></div>
              <p>{{testimonials[1]?.AuthorName}}</p>
            </figure>
            
          </div>
        </div>
  
        <div>
          <div class="testimonial">
  
            <blockquote class="mb-5">
              <p>&ldquo;{{testimonials[2]?.Feedback}}&rdquo;</p>
            </blockquote>
            <figure class="mb-4 d-flex align-items-center justify-content-center">
              <div><img src="{{testimonials[2]?.ProfileUrl}}" onError="this.src='assets/images/hero_1.jpg';" alt="{{testimonials[2]?.AuthorName}}"
                title="{{testimonials[2]?.AuthorName}}" class="w-50 img-fluid mb-3"></div>
              <p>{{testimonials[2]?.AuthorName}}</p>
            </figure>
  
            
          </div>
        </div>
  
        <div>
          <div class="testimonial">
  
            <blockquote class="mb-5">
              <p>&ldquo;{{testimonials[3]?.Feedback}}&rdquo;</p>
            </blockquote>
            <figure class="mb-4 d-flex align-items-center justify-content-center">
              <div><img src="{{testimonials[3]?.ProfileUrl}}" alt="{{testimonials[3]?.AuthorName}}"
                title="{{testimonials[3]?.AuthorName}}" alt="Image" class="w-50 img-fluid mb-3"></div>
              <p>{{testimonials[3]?.AuthorName}}</p>
            </figure>
  
          </div>
        </div>
  
      </div>
  </section>